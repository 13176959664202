/*!
    Feather
    Plugin URL: https://feathericons.com/
!*/

@font-face {
  font-family: "feather";
  src: url('../fonts/feather.eot?t=1525787366991'); /* IE9*/
  src: url('../fonts/feather.eot?t=1525787366991#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/feather.woff?t=1525787366991') format('woff'), /* chrome, firefox */
  url('../fonts/feather.ttf?t=1525787366991') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../fonts/feather.svg?t=1525787366991#feather') format('svg'); /* iOS 4.1- */
}

.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-feather-alert-octagon:before { content: "\e81b"; }

.icon-feather-alert-circle:before { content: "\e81c"; }

.icon-feather-activity:before { content: "\e81d"; }

.icon-feather-alert-triangle:before { content: "\e81e"; }

.icon-feather-align-center:before { content: "\e81f"; }

.icon-feather-airplay:before { content: "\e820"; }

.icon-feather-align-justify:before { content: "\e821"; }

.icon-feather-align-left:before { content: "\e822"; }

.icon-feather-align-right:before { content: "\e823"; }

.icon-feather-arrow-down-left:before { content: "\e824"; }

.icon-feather-arrow-down-right:before { content: "\e825"; }

.icon-feather-anchor:before { content: "\e826"; }

.icon-feather-aperture:before { content: "\e827"; }

.icon-feather-arrow-left:before { content: "\e828"; }

.icon-feather-arrow-right:before { content: "\e829"; }

.icon-feather-arrow-down:before { content: "\e82a"; }

.icon-feather-arrow-up-left:before { content: "\e82b"; }

.icon-feather-arrow-up-right:before { content: "\e82c"; }

.icon-feather-arrow-up:before { content: "\e82d"; }

.icon-feather-award:before { content: "\e82e"; }

.icon-feather-bar-chart:before { content: "\e82f"; }

.icon-feather-at-sign:before { content: "\e830"; }

.icon-feather-bar-chart-2:before { content: "\e831"; }

.icon-feather-battery-charging:before { content: "\e832"; }

.icon-feather-bell-off:before { content: "\e833"; }

.icon-feather-battery:before { content: "\e834"; }

.icon-feather-bluetooth:before { content: "\e835"; }

.icon-feather-bell:before { content: "\e836"; }

.icon-feather-book:before { content: "\e837"; }

.icon-feather-briefcase:before { content: "\e838"; }

.icon-feather-camera-off:before { content: "\e839"; }

.icon-feather-calendar:before { content: "\e83a"; }

.icon-feather-bookmark:before { content: "\e83b"; }

.icon-feather-box:before { content: "\e83c"; }

.icon-feather-camera:before { content: "\e83d"; }

.icon-feather-check-circle:before { content: "\e83e"; }

.icon-feather-check:before { content: "\e83f"; }

.icon-feather-check-square:before { content: "\e840"; }

.icon-feather-cast:before { content: "\e841"; }

.icon-feather-chevron-down:before { content: "\e842"; }

.icon-feather-chevron-left:before { content: "\e843"; }

.icon-feather-chevron-right:before { content: "\e844"; }

.icon-feather-chevron-up:before { content: "\e845"; }

.icon-feather-chevrons-down:before { content: "\e846"; }

.icon-feather-chevrons-right:before { content: "\e847"; }

.icon-feather-chevrons-up:before { content: "\e848"; }

.icon-feather-chevrons-left:before { content: "\e849"; }

.icon-feather-circle:before { content: "\e84a"; }

.icon-feather-clipboard:before { content: "\e84b"; }

.icon-feather-chrome:before { content: "\e84c"; }

.icon-feather-clock:before { content: "\e84d"; }

.icon-feather-cloud-lightning:before { content: "\e84e"; }

.icon-feather-cloud-drizzle:before { content: "\e84f"; }

.icon-feather-cloud-rain:before { content: "\e850"; }

.icon-feather-cloud-off:before { content: "\e851"; }

.icon-feather-codepen:before { content: "\e852"; }

.icon-feather-cloud-snow:before { content: "\e853"; }

.icon-feather-compass:before { content: "\e854"; }

.icon-feather-copy:before { content: "\e855"; }

.icon-feather-corner-down-right:before { content: "\e856"; }

.icon-feather-corner-down-left:before { content: "\e857"; }

.icon-feather-corner-left-down:before { content: "\e858"; }

.icon-feather-corner-left-up:before { content: "\e859"; }

.icon-feather-corner-up-left:before { content: "\e85a"; }

.icon-feather-corner-up-right:before { content: "\e85b"; }

.icon-feather-corner-right-down:before { content: "\e85c"; }

.icon-feather-corner-right-up:before { content: "\e85d"; }

.icon-feather-cpu:before { content: "\e85e"; }

.icon-feather-credit-card:before { content: "\e85f"; }

.icon-feather-crosshair:before { content: "\e860"; }

.icon-feather-disc:before { content: "\e861"; }

.icon-feather-delete:before { content: "\e862"; }

.icon-feather-download-cloud:before { content: "\e863"; }

.icon-feather-download:before { content: "\e864"; }

.icon-feather-droplet:before { content: "\e865"; }

.icon-feather-edit-2:before { content: "\e866"; }

.icon-feather-edit:before { content: "\e867"; }

.icon-feather-edit-1:before { content: "\e868"; }

.icon-feather-external-link:before { content: "\e869"; }

.icon-feather-eye:before { content: "\e86a"; }

.icon-feather-feather:before { content: "\e86b"; }

.icon-feather-facebook:before { content: "\e86c"; }

.icon-feather-file-minus:before { content: "\e86d"; }

.icon-feather-eye-off:before { content: "\e86e"; }

.icon-feather-fast-forward:before { content: "\e86f"; }

.icon-feather-file-text:before { content: "\e870"; }

.icon-feather-film:before { content: "\e871"; }

.icon-feather-file:before { content: "\e872"; }

.icon-feather-file-plus:before { content: "\e873"; }

.icon-feather-folder:before { content: "\e874"; }

.icon-feather-filter:before { content: "\e875"; }

.icon-feather-flag:before { content: "\e876"; }

.icon-feather-globe:before { content: "\e877"; }

.icon-feather-grid:before { content: "\e878"; }

.icon-feather-heart:before { content: "\e879"; }

.icon-feather-home:before { content: "\e87a"; }

.icon-feather-github:before { content: "\e87b"; }

.icon-feather-image:before { content: "\e87c"; }

.icon-feather-inbox:before { content: "\e87d"; }

.icon-feather-layers:before { content: "\e87e"; }

.icon-feather-info:before { content: "\e87f"; }

.icon-feather-instagram:before { content: "\e880"; }

.icon-feather-layout:before { content: "\e881"; }

.icon-feather-link-2:before { content: "\e882"; }

.icon-feather-life-buoy:before { content: "\e883"; }

.icon-feather-link:before { content: "\e884"; }

.icon-feather-log-in:before { content: "\e885"; }

.icon-feather-list:before { content: "\e886"; }

.icon-feather-lock:before { content: "\e887"; }

.icon-feather-log-out:before { content: "\e888"; }

.icon-feather-loader:before { content: "\e889"; }

.icon-feather-mail:before { content: "\e88a"; }

.icon-feather-maximize-2:before { content: "\e88b"; }

.icon-feather-map:before { content: "\e88c"; }

.icon-feather-map-pin:before { content: "\e88e"; }

.icon-feather-menu:before { content: "\e88f"; }

.icon-feather-message-circle:before { content: "\e890"; }

.icon-feather-message-square:before { content: "\e891"; }

.icon-feather-minimize-2:before { content: "\e892"; }

.icon-feather-mic-off:before { content: "\e893"; }

.icon-feather-minus-circle:before { content: "\e894"; }

.icon-feather-mic:before { content: "\e895"; }

.icon-feather-minus-square:before { content: "\e896"; }

.icon-feather-minus:before { content: "\e897"; }

.icon-feather-moon:before { content: "\e898"; }

.icon-feather-monitor:before { content: "\e899"; }

.icon-feather-more-vertical:before { content: "\e89a"; }

.icon-feather-more-horizontal:before { content: "\e89b"; }

.icon-feather-move:before { content: "\e89c"; }

.icon-feather-music:before { content: "\e89d"; }

.icon-feather-navigation-2:before { content: "\e89e"; }

.icon-feather-navigation:before { content: "\e89f"; }

.icon-feather-octagon:before { content: "\e8a0"; }

.icon-feather-package:before { content: "\e8a1"; }

.icon-feather-pause-circle:before { content: "\e8a2"; }

.icon-feather-pause:before { content: "\e8a3"; }

.icon-feather-percent:before { content: "\e8a4"; }

.icon-feather-phone-call:before { content: "\e8a5"; }

.icon-feather-phone-forwarded:before { content: "\e8a6"; }

.icon-feather-phone-missed:before { content: "\e8a7"; }

.icon-feather-phone-off:before { content: "\e8a8"; }

.icon-feather-phone-incoming:before { content: "\e8a9"; }

.icon-feather-phone:before { content: "\e8aa"; }

.icon-feather-phone-outgoing:before { content: "\e8ab"; }

.icon-feather-pie-chart:before { content: "\e8ac"; }

.icon-feather-play-circle:before { content: "\e8ad"; }

.icon-feather-play:before { content: "\e8ae"; }

.icon-feather-plus-square:before { content: "\e8af"; }

.icon-feather-plus-circle:before { content: "\e8b0"; }

.icon-feather-plus:before { content: "\e8b1"; }

.icon-feather-pocket:before { content: "\e8b2"; }

.icon-feather-printer:before { content: "\e8b3"; }

.icon-feather-power:before { content: "\e8b4"; }

.icon-feather-radio:before { content: "\e8b5"; }

.icon-feather-repeat:before { content: "\e8b6"; }

.icon-feather-refresh-ccw:before { content: "\e8b7"; }

.icon-feather-rewind:before { content: "\e8b8"; }

.icon-feather-rotate-ccw:before { content: "\e8b9"; }

.icon-feather-refresh-cw:before { content: "\e8ba"; }

.icon-feather-rotate-cw:before { content: "\e8bb"; }

.icon-feather-save:before { content: "\e8bc"; }

.icon-feather-search:before { content: "\e8bd"; }

.icon-feather-server:before { content: "\e8be"; }

.icon-feather-scissors:before { content: "\e8bf"; }

.icon-feather-share-2:before { content: "\e8c0"; }

.icon-feather-share:before { content: "\e8c1"; }

.icon-feather-shield:before { content: "\e8c2"; }

.icon-feather-settings:before { content: "\e8c3"; }

.icon-feather-skip-back:before { content: "\e8c4"; }

.icon-feather-shuffle:before { content: "\e8c5"; }

.icon-feather-sidebar:before { content: "\e8c6"; }

.icon-feather-skip-forward:before { content: "\e8c7"; }

.icon-feather-slack:before { content: "\e8c8"; }

.icon-feather-slash:before { content: "\e8c9"; }

.icon-feather-smartphone:before { content: "\e8ca"; }

.icon-feather-square:before { content: "\e8cb"; }

.icon-feather-speaker:before { content: "\e8cc"; }

.icon-feather-star:before { content: "\e8cd"; }

.icon-feather-stop-circle:before { content: "\e8ce"; }

.icon-feather-sun:before { content: "\e8cf"; }

.icon-feather-sunrise:before { content: "\e8d0"; }

.icon-feather-tablet:before { content: "\e8d1"; }

.icon-feather-tag:before { content: "\e8d2"; }

.icon-feather-sunset:before { content: "\e8d3"; }

.icon-feather-target:before { content: "\e8d4"; }

.icon-feather-thermometer:before { content: "\e8d5"; }

.icon-feather-thumbs-up:before { content: "\e8d6"; }

.icon-feather-thumbs-down:before { content: "\e8d7"; }

.icon-feather-toggle-left:before { content: "\e8d8"; }

.icon-feather-toggle-right:before { content: "\e8d9"; }

.icon-feather-trash-2:before { content: "\e8da"; }

.icon-feather-trash:before { content: "\e8db"; }

.icon-feather-trending-up:before { content: "\e8dc"; }

.icon-feather-trending-down:before { content: "\e8dd"; }

.icon-feather-triangle:before { content: "\e8de"; }

.icon-feather-type:before { content: "\e8df"; }

.icon-feather-twitter:before { content: "\e8e0"; }

.icon-feather-upload:before { content: "\e8e1"; }

.icon-feather-umbrella:before { content: "\e8e2"; }

.icon-feather-upload-cloud:before { content: "\e8e3"; }

.icon-feather-unlock:before { content: "\e8e4"; }

.icon-feather-user-check:before { content: "\e8e5"; }

.icon-feather-user-minus:before { content: "\e8e6"; }

.icon-feather-user-plus:before { content: "\e8e7"; }

.icon-feather-user-x:before { content: "\e8e8"; }

.icon-feather-user:before { content: "\e8e9"; }

.icon-feather-users:before { content: "\e8ea"; }

.icon-feather-video-off:before { content: "\e8eb"; }

.icon-feather-video:before { content: "\e8ec"; }

.icon-feather-voicemail:before { content: "\e8ed"; }

.icon-feather-volume-x:before { content: "\e8ee"; }

.icon-feather-volume-2:before { content: "\e8ef"; }

.icon-feather-volume-1:before { content: "\e8f0"; }

.icon-feather-volume:before { content: "\e8f1"; }

.icon-feather-watch:before { content: "\e8f2"; }

.icon-feather-wifi:before { content: "\e8f3"; }

.icon-feather-x-square:before { content: "\e8f4"; }

.icon-feather-wind:before { content: "\e8f5"; }

.icon-feather-x:before { content: "\e8f6"; }

.icon-feather-x-circle:before { content: "\e8f7"; }

.icon-feather-zap:before { content: "\e8f8"; }

.icon-feather-zoom-in:before { content: "\e8f9"; }

.icon-feather-zoom-out:before { content: "\e8fa"; }

.icon-feather-command:before { content: "\e8fb"; }

.icon-feather-cloud:before { content: "\e8fc"; }

.icon-feather-hash:before { content: "\e8fd"; }

.icon-feather-headphones:before { content: "\e8fe"; }

.icon-feather-underline:before { content: "\e8ff"; }

.icon-feather-italic:before { content: "\e900"; }

.icon-feather-bold:before { content: "\e901"; }

.icon-feather-crop:before { content: "\e902"; }

.icon-feather-help-circle:before { content: "\e903"; }

.icon-feather-paperclip:before { content: "\e904"; }

.icon-feather-shopping-cart:before { content: "\e905"; }

.icon-feather-tv:before { content: "\e906"; }

.icon-feather-wifi-off:before { content: "\e907"; }

.icon-feather-minimize:before { content: "\e88d"; }

.icon-feather-maximize:before { content: "\e908"; }

.icon-feather-gitlab:before { content: "\e909"; }

.icon-feather-sliders:before { content: "\e90a"; }

.icon-feather-star-on:before { content: "\e90b"; }

.icon-feather-heart-on:before { content: "\e90c"; }

.icon-feather-archive:before { content: "\e90d"; }

.icon-feather-arrow-down-circle:before { content: "\e90e"; }

.icon-feather-arrow-up-circle:before { content: "\e90f"; }

.icon-feather-arrow-left-circle:before { content: "\e910"; }

.icon-feather-arrow-right-circle:before { content: "\e911"; }

.icon-feather-bar-chart-line-:before { content: "\e912"; }

.icon-feather-bar-chart-line:before { content: "\e913"; }

.icon-feather-book-open:before { content: "\e914"; }

.icon-feather-code:before { content: "\e915"; }

.icon-feather-database:before { content: "\e916"; }

.icon-feather-dollar-sign:before { content: "\e917"; }

.icon-feather-folder-plus:before { content: "\e918"; }

.icon-feather-gift:before { content: "\e919"; }

.icon-feather-folder-minus:before { content: "\e91a"; }

.icon-feather-git-commit:before { content: "\e91b"; }

.icon-feather-git-branch:before { content: "\e91c"; }

.icon-feather-git-pull-request:before { content: "\e91d"; }

.icon-feather-git-merge:before { content: "\e91e"; }

.icon-feather-linkedin:before { content: "\e91f"; }

.icon-feather-hard-drive:before { content: "\e920"; }

.icon-feather-more-vertical-:before { content: "\e921"; }

.icon-feather-more-horizontal-:before { content: "\e922"; }

.icon-feather-rss:before { content: "\e923"; }

.icon-feather-send:before { content: "\e924"; }

.icon-feather-shield-off:before { content: "\e925"; }

.icon-feather-shopping-bag:before { content: "\e926"; }

.icon-feather-terminal:before { content: "\e927"; }

.icon-feather-truck:before { content: "\e928"; }

.icon-feather-zap-off:before { content: "\e929"; }

.icon-feather-youtube:before { content: "\e92a"; }
